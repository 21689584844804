<template>
   <div>
      <template v-if="!loading">
         <apexchart class="card-rounded-bottom" :options="chartOptions" :series="series" type="bar" height="250"></apexchart>
         <div class="card-spacer bg-white card-rounded flex-grow-1">
            <div class="row m-0">
               <div class="col px-8 text-center">
                  <div class="font-size-sm text-muted font-weight-bold">{{ $t('INTERN.CHART.THIS_MONTH') }}</div>
                  <div class="font-size-h4 font-weight-bolder">{{ thisMonth }}</div>
               </div>
               <div class="col px-8 text-center">
                  <div class="font-size-sm text-muted font-weight-bold">{{ $t('INTERN.CHART.THIS_YEAR') }}</div>
                  <div class="font-size-h4 font-weight-bolder">{{ thisYear }}</div>
               </div>
            </div>
         </div>
      </template>
   </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;
export default {
   data: () => ({
      chartOptions: {},
      loading: true,
      sortByApi: 'start',
      series: [],
      thisMonth: 0,
      thisYear: 0,
      categories: []
   }),
   async mounted() {
      await this.getStagiaires();
      this.loading = false;
      this.initChart();
   },
   methods: {
      initChart() {
         this.chartOptions = {
            chart: {
               type: 'bar',
               toolbar: {
                  show: false
               }
            },
            legend: {
               show: true,
               showForSingleSeries: true
            },
            plotOptions: {
               bar: {
                  horizontal: false,
                  // columnWidth: ['30%'],
                  dataLabels: {
                     position: 'top'
                  }
               }
            },
            dataLabels: {
               enabled: true,
               offsetY: -25
            },
            stroke: {
               show: true,
               width: 2,
               colors: ['transparent']
            },
            xaxis: {
               categories: this.categories,
               tickPlacement: 'on',
               labels: {
                  show: true,
                  rotate: 0,
                  style: {
                     colors: [
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0'
                     ],
                     fontSize: '12px',
                     fontFamily: this.layoutConfig('font-family')
                  }
               }
            },
            yaxis: {
               axisBorder: {
                  show: false
               },
               axisTicks: {
                  show: false
               },
               labels: {
                  show: false
               }
            },
            fill: {
               opacity: 1
            },
            tooltip: {
               enabled: false
            }
         };
      },
      async getStagiaires() {
         await axios.get(API_URL + '/usersthemes/stats/stagiairesPerTheme/' + this.$store.getters.currentUser.customer.id).then(async ({ data }) => {
            this.thisYear = data.thisYear;
            this.thisMonth = data.thisMonth;
            this.categories = data.months;
            this.categories = this.abregerMois(this.categories);

            if (data.themes) {
               Object.keys(data.themes).forEach((key) => {
                  this.series.push({ name: key, data: data.themes[key] });
               });
            } else {
               this.series.push({ name: 'Aucun stagiaire formé cette année', data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] });
            }
         });
      },
      abregerMois(mois) {
         const abreviations = ['JANV', 'FÉVR', 'MARS', 'AVR', 'MAI', 'JUIN', 'JUIL', 'AOÛT', 'SEPT', 'OCT', 'NOV', 'DÉC'];
         const abreviationsMobile = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

         const moisAbreges = mois.map((moisComplet, index) => {
            if (this.isMobile()) {
               return abreviationsMobile[index];
            } else {
               return abreviations[index];
            }
         });
         return moisAbreges;
      }
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         layoutConfig: 'layoutConfig'
      }),
      ...mapState({
         error: (state) => state.action.error
      })
   }
};
</script>

<style></style>
