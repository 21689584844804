var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12"},[_c('b-jumbotron',{staticClass:"mb-0",attrs:{"header":`Espace ${_vm.role === 'Gestionnaire' ? 'Formation' : 'Stagiaire'}`,"lead":`Bienvenue sur votre espace ${_vm.role === 'Gestionnaire' ? 'formation' : 'stagiaire'} ${_vm.currentUser.firstname} ${
               _vm.currentUser.lastname
            }`}})],1)]),(_vm.role === 'Gestionnaire')?_c('div',{class:_vm.isMobile() ? '' : 'row'},[_c('div',{class:_vm.isMobile() ? 'row mt-8' : 'col-xxl-6 mt-8'},[_c('div',{class:_vm.isMobile() ? 'col-xxl-12' : ''},[_c('div',{staticClass:"card card-custom bg-radial-gradient-primary card-stretch custom"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title font-weight-bolder text-white"},[_vm._v(_vm._s(_vm.$t('INVOICE.CHART.TITLE')))])]),_c('div',{staticClass:"card-body d-flex flex-column p-0"},[_c('InvoiceChart')],1)])])]),_c('div',{class:_vm.isMobile() ? 'row mt-8' : 'col-xxl-6 mt-8'},[_c('div',{class:_vm.isMobile() ? 'col-xxl-12' : ''},[_c('div',{staticClass:"card card-custom bg-radial-gradient-success card-stretch custom"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title font-weight-bolder text-white"},[_vm._v(_vm._s(_vm.$t('ACTION.CHART.TITLE')))])]),_c('div',{staticClass:"card-body d-flex flex-column p-0"},[_c('ActionChart')],1)])])])]):_vm._e(),(_vm.role === 'Gestionnaire')?_c('div',{staticClass:"row mt-8"},[_c('div',{staticClass:"col-xxl-12"},[_c('div',{staticClass:"card card-custom bg-radial-gradient-info card-stretch custom"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title font-weight-bolder text-white"},[_vm._v(_vm._s(_vm.$t('INTERN.CHART.TITLE')))])]),_c('div',{staticClass:"card-body d-flex flex-column p-0"},[_c('InternThemeChart')],1)])])]):_vm._e(),(
         (_vm.tauxFormationChart && _vm.role === 'Gestionnaire' && _vm.currentUser.tauxEtSeuil === 1) ||
         _vm.currentUser.role === 'Administrateur' ||
         _vm.currentUser.role === 'Super Admin'
      )?_c('div',{staticClass:"row mt-8"},[_c('div',{staticClass:"col-xxl-12"},[_c('div',{staticClass:"card card-custom card-stretch custom"},[_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('INTERN.CHARTTAUXFORMATION.TITLE')))])]),_c('div',{staticClass:"card-body d-flex flex-column p-0"},[_c('TauxFormationChart',{on:{"showTauxFormationChart":function($event){_vm.tauxFormationChart = false}}})],1)])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }