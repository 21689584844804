<template>
   <div>
      <template v-if="!loading">
         <apexchart :options="chartOptions" :series="series" class="card-rounded-bottom" height="200" type="bar"></apexchart>
         <div class="card-spacer bg-white card-rounded flex-grow-1">
            <div class="row m-0">
               <div class="col px-8 text-center">
                  <div class="font-size-sm text-muted font-weight-bold">{{ $t('INVOICE.CHART.THIS_MONTH') }}</div>
                  <div class="font-size-h4 font-weight-bolder">{{ thisMonth | toCurrency }}</div>
               </div>
               <div class="col px-8 text-center">
                  <div class="font-size-sm text-muted font-weight-bold">{{ $t('INVOICE.CHART.THIS_YEAR') }}</div>
                  <div class="font-size-h4 font-weight-bolder">{{ thisYear | toCurrency }}</div>
               </div>
            </div>
         </div>
      </template>
   </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import authHeader from './../../../../core/services/store/api-orion/auth-header';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_ORION_URL;

const monthName = (item) => moment(item.fac_date).format('MMMM').toUpperCase();

export default {
   data() {
      return {
         chartOptions: {},
         loading: true,
         sortByApi: 'date',
         series: [
            {
               name: this.$t('INVOICE.CHART.AMOUNT'),
               data: []
            }
         ],
         thisMonth: 0,
         thisYear: 0,
         categories: []
      };
   },
   async mounted() {
      await this.getInvoices();
      this.loading = false;
      this.initChart();
   },
   methods: {
      sum: (array, key) => array.reduce((a, b) => a + (b[key] || 0), 0),
      initChart() {
         this.chartOptions = {
            chart: {
               type: 'bar',
               toolbar: {
                  show: false
               }
            },
            plotOptions: {
               bar: {
                  horizontal: false,
                  columnWidth: ['30%'],
                  dataLabels: {
                     position: 'top'
                  }
               }
            },
            legend: {
               show: false
            },
            dataLabels: {
               enabled: true,
               offsetY: -25,
               formatter: (val) => val + ' €'
            },
            stroke: {
               show: true,
               width: 2,
               colors: ['transparent']
            },
            xaxis: {
               categories: this.categories,
               tickPlacement: 'on',
               labels: {
                  rotate: 90,
                  style: {
                     colors: [
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0'
                     ],
                     fontSize: '12px',
                     fontFamily: this.layoutConfig('font-family')
                  }
               }
            },
            yaxis: {
               axisBorder: {
                  show: false
               },
               axisTicks: {
                  show: false
               },
               labels: {
                  show: false
               }
            },
            fill: {
               opacity: 1
            },
            states: {
               normal: {
                  filter: {
                     type: 'none',
                     value: 0
                  }
               },
               hover: {
                  filter: {
                     type: 'none',
                     value: 0
                  }
               },
               active: {
                  allowMultipleDataPointsSelection: false,
                  filter: {
                     type: 'none',
                     value: 0
                  }
               }
            },
            tooltip: {
               enabled: false
            },
            colors: ['#ffc0bc'],
            grid: {
               borderColor: this.layoutConfig('colors.gray.gray-200'),
               strokeDashArray: 4,
               yaxis: {
                  lines: {
                     show: false
                  }
               }
            }
         };
      },
      async getInvoices() {
         const params = {
            year: moment().year()
         };

         const { data } = await axios.get(API_URL + 'factures', { headers: authHeader(), params });
         if (!data) return;

         const invoices = _.groupBy(data, monthName);
         const invoicesOrdered = Object.fromEntries(
            Object.entries(invoices).sort(([a], [b]) => moment(a, 'MMMM').format('MM') - moment(b, 'MMMM').format('MM'))
         );

         const sum = (arr, key) => arr.reduce((acc, obj) => acc + obj[key], 0);
         this.categories.push(...Object.keys(invoicesOrdered));
         this.series[0].data.push(...Object.values(invoicesOrdered).map((invoicesOrdered) => sum(invoicesOrdered, 'fac_total_ht')));

         if (this.categories.length < 12) {
            const missingMonths = Array.from({ length: 12 }, (_, i) =>
               moment(i + 1, 'MM')
                  .format('MMMM')
                  .toUpperCase()
            ).filter((month) => !this.categories.includes(month));

            missingMonths.forEach((missingMonth) => {
               const index = moment(missingMonth, 'MMMM').format('MM') - 1;
               this.categories.splice(index, 0, missingMonth);
               this.series[0].data.splice(index, 0, 0);
            });
         }

         this.thisYear = this.series[0].data.reduce((previousValue, currentValue) => previousValue + currentValue, this.thisYear);

         const currentMonth = moment().format('MMMM').toUpperCase();
         const index = this.categories.indexOf(currentMonth);
         this.thisMonth = index === -1 ? 0 : this.series[0].data[index];
         this.categories = this.abregerMois(this.categories);
      },
      abregerMois(mois) {
         const abreviations = ['JANV', 'FÉVR', 'MARS', 'AVR', 'MAI', 'JUIN', 'JUIL', 'AOÛT', 'SEPT', 'OCT', 'NOV', 'DÉC'];
         const abreviationsMobile = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

         const moisAbreges = mois.map((moisComplet, index) => {
            if (this.isMobile()) {
               return abreviationsMobile[index];
            } else {
               return abreviations[index];
            }
         });
         return moisAbreges;
      }
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         layoutConfig: 'layoutConfig'
      }),
      ...mapState({
         error: (state) => state.invoice.error
      })
   }
};
</script>
