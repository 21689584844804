<template>
   <div>
      <template v-if="!loading">
         <div class="d-flex flex-wrap justify-content-around">
            <div class="d-flex flex-column justify-center align-items-center" v-for="(serie, index) in series" :key="index">
               <p class="font-weight-bold h5">{{ index }}</p>
               <apexchart :options="chartOptions" :series="serie" type="pie" height="250"></apexchart>
            </div>
         </div>
      </template>
   </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;

export default {
   data: () => ({
      chartOptions: {},
      loading: true,
      sortByApi: 'start',
      series: []
   }),
   async mounted() {
      await this.getStagiaires();
      this.loading = false;
      this.initChart();
   },
   methods: {
      initChart() {
         this.chartOptions = {
            chart: {
               width: 380,
               type: 'pie'
            },
            labels: ['Formé', 'Reste à former'],
            dataLabels: {
               enabled: true,
               style: {
                  colors: ['#111']
               },
               background: {
                  enabled: true,
                  foreColor: '#fff',
                  borderWidth: 0
               }
            },
            tooltip: {
               enabled: true,
               y: {
                  formatter: (val) => val + '%'
               }
            }
         };
      },
      async getStagiaires() {
         await axios.get(API_URL + '/usersthemes/stats/tauxAtteintPerTheme/' + this.$store.getters.currentUser.customer.id).then(async ({ data }) => {
            if (Object.keys(data).length > 0) {
               this.series = data;
            } else {
               this.$emit('showTauxFormationChart', false);
            }
         });
      }
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         layoutConfig: 'layoutConfig'
      }),
      ...mapState({
         error: (state) => state.action.error
      })
   }
};
</script>

<style></style>
