<template>
   <div>
      <template v-if="!loading">
         <apexchart class="card-rounded-bottom" :options="chartOptions" :series="series" type="bar" height="200"></apexchart>
         <div class="card-spacer bg-white card-rounded flex-grow-1">
            <div class="row m-0">
               <div class="col px-8 text-center">
                  <div class="font-size-sm text-muted font-weight-bold">{{ $t('ACTION.CHART.THIS_MONTH') }}</div>
                  <div class="font-size-h4 font-weight-bolder">{{ thisMonth }}</div>
               </div>
               <div class="col px-8 text-center">
                  <div class="font-size-sm text-muted font-weight-bold">{{ $t('ACTION.CHART.THIS_YEAR') }}</div>
                  <div class="font-size-h4 font-weight-bolder">{{ thisYear }}</div>
               </div>
            </div>
         </div>
      </template>
   </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import authHeader from '@/core/services/store/api-orion/auth-header';

const API_URL = process.env.VUE_APP_API_ORION_URL;

const monthName = (item) => moment(item.date_deb).format('MMMM').toUpperCase();

const yearName = (item) => moment(item.date_deb).format('YYYY').toUpperCase();

export default {
   data() {
      return {
         chartOptions: {},
         loading: true,
         sortByApi: 'start',
         series: [
            {
               name: this.$t('ACTION.CHART.AMOUNT'),
               data: []
            }
         ],
         thisMonth: 0,
         thisYear: 0,
         categories: []
      };
   },
   async mounted() {
      await this.getActions();
      this.loading = false;
      this.initChart();
   },
   methods: {
      sum: (array, key) => array.reduce((a, b) => a + (b[key] || 0), 0),
      initChart() {
         this.chartOptions = {
            chart: {
               type: 'bar',
               toolbar: {
                  show: false
               }
            },
            plotOptions: {
               bar: {
                  horizontal: false,
                  columnWidth: ['30%'],
                  dataLabels: {
                     position: 'top'
                  }
               }
            },
            legend: {
               show: false
            },
            dataLabels: {
               enabled: true,
               offsetY: -25
            },
            stroke: {
               show: true,
               width: 2,
               colors: ['transparent']
            },
            xaxis: {
               categories: this.categories,
               tickPlacement: 'on',
               labels: {
                  show: true,
                  rotate: 0,
                  style: {
                     colors: [
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0',
                        '#cbf2f0'
                     ],
                     fontSize: '12px',
                     fontFamily: this.layoutConfig('font-family')
                  }
               }
            },
            yaxis: {
               axisBorder: {
                  show: false
               },
               axisTicks: {
                  show: false
               },
               labels: {
                  show: false
               }
            },
            fill: {
               opacity: 1
            },
            states: {
               normal: {
                  filter: {
                     type: 'none',
                     value: 0
                  }
               },
               hover: {
                  filter: {
                     type: 'none',
                     value: 0
                  }
               },
               active: {
                  allowMultipleDataPointsSelection: false,
                  filter: {
                     type: 'none',
                     value: 0
                  }
               }
            },
            tooltip: {
               enabled: false
            },
            colors: ['#cbf2f0'],
            grid: {
               borderColor: this.layoutConfig('colors.gray.gray-200'),
               strokeDashArray: 4,
               yaxis: {
                  lines: {
                     show: false
                  }
               },
               padding: {
                  top: 0 //15
               }
            }
         };
      },
      async getActions() {
         const params = {
            year: moment().format('YYYY')
         };

         await axios.get(API_URL + `actions_clients`, { headers: authHeader(), params }).then(async ({ data }) => {
            if (data) {
               const actualYear = new Date().getFullYear();
               let actualMonth = new Date().getMonth() + 1;
               if (actualMonth < 10) {
                  actualMonth = '0' + actualMonth;
               }
               const actionsActualYear = _.groupBy(data, yearName);
               const actions = _.groupBy(actionsActualYear[actualYear], monthName);
               //order actions by month name order by month number
               const actionsOrdered = {};
               Object.keys(actions)
                  .sort((a, b) => {
                     return moment(a, 'MMMM').format('MM') - moment(b, 'MMMM').format('MM');
                  })
                  .forEach(function (key) {
                     actionsOrdered[key] = actions[key];
                  });
               _.entries(actionsOrdered).map(([month]) => this.categories.push(month));
               _.values(actionsOrdered).map((actionsOrdered) => {
                  this.series[0].data.push(actionsOrdered.length);
               });
               if (this.categories.length < 12) {
                  //find missing months and add it at the right place
                  const missingMonths = [];
                  for (let i = 1; i <= 12; i++) {
                     if (i < 10) {
                        i = '0' + i;
                     }
                     if (!this.categories.includes(moment(i, 'MM').format('MMMM').toUpperCase())) {
                        missingMonths.push(moment(i, 'MM').format('MMMM').toUpperCase());
                     }
                  }
                  missingMonths.map((missingMonth) => {
                     //push missing months at the right place
                     this.categories.splice(moment(missingMonth, 'MMMM').format('MM') - 1, 0, missingMonth);
                     this.series[0].data.splice(moment(missingMonth, 'MMMM').format('MM') - 1, 0, 0);
                  });
               }
               this.categories = this.abregerMois(this.categories);

               if (actionsActualYear[actualYear]) {
                  this.thisYear = actionsActualYear[actualYear].length;
               } else {
                  this.thisYear = 0;
               }
               if (actionsOrdered[moment().format('MMMM').toUpperCase()]) {
                  this.thisMonth = actionsOrdered[moment().format('MMMM').toUpperCase()].length;
               } else {
                  this.thisMonth = 0;
               }
            }
         });
      },
      abregerMois(mois) {
         const abreviations = ['JANV', 'FÉVR', 'MARS', 'AVR', 'MAI', 'JUIN', 'JUIL', 'AOÛT', 'SEPT', 'OCT', 'NOV', 'DÉC'];
         const abreviationsMobile = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

         const moisAbreges = mois.map((moisComplet, index) => {
            if (this.isMobile()) {
               return abreviationsMobile[index];
            } else {
               return abreviations[index];
            }
         });
         return moisAbreges;
      }
   },
   computed: {
      ...mapMutations({
         resetError: 'resetError'
      }),
      ...mapGetters({
         layoutConfig: 'layoutConfig'
      }),
      ...mapState({
         error: (state) => state.action.error
      })
   }
};
</script>

<style></style>
